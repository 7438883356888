<template>
  <div>
    <va-card title="customer organization platform">
      <template slot="actions">
        <va-button icon="fa fa-list"
          @click="showModel()" color="gray">List</va-button>
      </template>
      <CustomerOrgFtr
        ref="CustomerOrgFtr"
        @filter-pt="filterPlatform"/>
      <CustomerOrgPtUpd
        :is-show="is_show"
        :customer-org-pt="customer_org_pt"
        @hide-model="hideModel()"
        @data-updated="refresh_data()"
        :license_id="license_id"
        :kyc_id="kyc_id"/>
    </va-card><br>
    <va-card title="customer organization platform table">
      <CustomerOrgPtTbl
        ref="customerOrgPtTbl"
        @show-model="getKyc"
        :license_id="license_id"
        @remove-disable="refresh_data()"
        @cancel-add="cancelAdd()"/>
    </va-card>
    <va-modal
      v-model="showRemoveModal"
      title="Exit"
      size='small'
      message="Are You Sure ? You will lost recently added records"
      okText="Confirm"
      cancelText="Cancel"
      @ok="cancelAdd()"
      @cancel="() => showRemoveModal = false" />
  </div>
</template>

<script>
import CustomerOrgFtr from './CustomerOrgFtr'
import CustomerOrgPtUpd from './CustomerOrgPtUpd'
import CustomerOrgPtTbl from './CustomerOrgPtTbl'
import config from '../../i18n/en.json'

import Vue from 'vue'
import vueResource from 'vue-resource'

Vue.use(vueResource)
export default {
  name: 'customer_org_platform',
  created () {
    Vue.$cookies.set('added-cops', JSON.stringify([]))
    Vue.$cookies.set('added-kycs', JSON.stringify([]))
    this.customer_org_pt_ = this.customer_org_pt
    this.setTodayDate()
  },
  beforeDestroy () {
    this.destroyItems()
  },
  components: {
    CustomerOrgFtr,
    CustomerOrgPtUpd,
    CustomerOrgPtTbl,
  },
  data () {
    return {
      is_show: false,
      license_id: '',
      kyc_id: '',
      showRemoveModal:false,
      hide_tbl: false,
      customer_org_pt_: {},
      customer_org_pt: {
        platform_name: {
          name: 'platform name',
          value: '',
          type: 'text',
          disabled: true,
        },
        customer_name: {
          name: 'customer name',
          value: '',
          type: 'text',
          disabled: true,
        },
        customer_org_id: {
          name: 'customer_org_id',
          value: '',
          type: 'id',
          hidden: true,
        },
        platform_id: {
          name: 'platform_id',
          value: '',
          type: 'id',
          hidden: true,
        },
        description: {
          name: 'description',
          value: '',
          required: true,
          type: 'text',
        },
        installation_type: {
          name: 'installation type',
          value: '',
          required: true,
          type: 'select',
          options: ['SINGLE', 'MULTIPLE'],
        },
        otc_license: {
          name: 'otc license',
          value: '',
          required: true,
          type: 'text',
        },
        subscription_cost: {
          name: 'subscription cost',
          value: '',
          required: true,
          type: 'number',
        },
        minimal_licenses: {
          name: 'minimal licenses',
          value: '',
          required: true,
          type: 'number',
        },
        renewal_mode: {
          name: 'renewal mode',
          value: '',
          required: true,
          type: 'select',
          options: ['MONTHLY', 'ANNUALY', 'NEVER'],
        },
        renewal_license_grace_period: {
          name: 'renewal license grace period',
          value: '',
          required: true,
          type: 'number',
        },
        user_name: {
          name: 'user name',
          value: '',
          required: true,
          type: 'text',
          pattern: '^[A-Za-z]+(?: +[A-Za-z]+)*$',
        },
        password: {
          name: 'password',
          value: '',
          required: true,
          type: 'password',
          pattern: '^[A-Za-z0-9][A-Za-z0-9_@!./#&+-^]*$',
        },
        support_user_name: {
          name: 'support user name',
          value: '',
          required: true,
          type: 'text',
          pattern: '^[A-Za-z]+(?: +[A-Za-z]+)*$',
        },
        support_password: {
          name: 'support password',
          value: '',
          required: true,
          type: 'password',
          pattern: '^[A-Za-z0-9][A-Za-z0-9_@!./#&+-^]*$',
        },
        mgmnt_ip: {
          name: 'management ip',
          value: '',
          required: true,
          type: 'text',
          pattern: '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
        },
        mgmnt_port: {
          name: 'management port',
          value: '',
          required: true,
          type: 'text',
          pattern: '^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$',
        },
        renewal_date: {
          name: 'renewal date',
          value: new Date(),
          required: true,
          type: 'date',
          disabledDate: {
            to: null,
          },
        },
        bill_start_date: {
          name: 'bill start date',
          value: new Date(),
          required: true,
          type: 'date',
          disabledDate: {
            to: null,
          },
        },
        billing_date: {
          name: 'billing date',
          value: new Date(),
          required: true,
          type: 'date',
          disabledDate: {
            to: null,
          },
        },
      }
    }
  },
  methods: {
    showModel(){
      let isAny = JSON.parse(Vue.$cookies.get('added-cops'))
      if (Vue.$cookies.get('edit-mode') && isAny.length){
        this.showRemoveModal = !this.showRemoveModal
      } else if (Vue.$cookies.get('edit-mode') || !isAny.length) {
        this.cancelAdd()
      } else {
        this.showRemoveModal = !this.showRemoveModal
      }
    },
    cancelAdd () {
      if (Vue.$cookies.get('edit-mode')) {
        Vue.$cookies.remove('selected-customer')
        Vue.$cookies.remove('edit-mode')
      }
      this.destroyItems()
    },
    destroyItems(){
      const addedCops = Vue.$cookies.get('added-cops')
      Vue.$cookies.set('added-cops', JSON.stringify([]))
      const addedKycs = Vue.$cookies.get('added-kycs')
      Vue.$cookies.set('added-kycs', JSON.stringify([]))
      Vue.$cookies.remove('selected-customer')
      this.deleteKycs(JSON.parse(addedKycs))
      .then(() => this.deleteCops(JSON.parse(addedCops)))
      .then(() => this.$emit('new-add-cancel'))
    },
    async deleteCops (addedCops) {
      for (let index = 0; index < addedCops.length; index++) {
        await new Promise((resolve, _) => {
          this.$http.delete(`${config.menu.host}customer_org_pt/${addedCops[index]}`)
            .then((res) => {
              resolve(res)
            })
        })
      }
    },
    async deleteKycs (addedKycs) {
      for (let index = 0; index < addedKycs.length; index++) {
        await new Promise((resolve, _) => {
          this.$http.delete(`${config.menu.host}customer_org_pt_kyc/${addedKycs[index]}`)
            .then((res) => {
              resolve(res)
            })
        })
      }
    },
    getKyc (cusOrgPt) {
      if(cusOrgPt.platform_name == 'CDN'){
        this.customer_org_pt_['reference_name'] = {
          name: 'reference name',
          value: '',
          required: true,
          type: 'text'
        }
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http
        .get(`${config.menu.host}customer_org_pt/pt/${cusOrgPt.platform_id}`)
        .then(res => {
          loader.hide()
          this.is_show = true
          this.license_id = cusOrgPt.license_id
          this.customer_org_pt = this.customer_org_pt_
          for (const key in cusOrgPt) {
            if (this.customer_org_pt[key]) { this.customer_org_pt[key].value = cusOrgPt[key] }
          }
          if (!res.data.length) return
          this.kyc_id = res.data[0].kyc_id
          const extra = {}
          extra.kyc_id = res.data[0].kyc_id
          extra.platform_id = res.data[0].platform_id
          const customer_org_pt = {}
          for (const key in this.customer_org_pt) {
            customer_org_pt[key] = this.customer_org_pt[key]
            if (key == 'mgmnt_port') {
              customer_org_pt[key] = this.customer_org_pt[key]
              res.data.forEach(field => {
                const temp = {
                  name: '',
                  value: '',
                  required: true,
                  type: '',
                  key : '',
                  checked : false,
                  custom_fields: true,
                  extra: extra,
                }
                temp.name = field.kyc_label
                temp.value = field.kyc_type == 'checkbox' ? (field.kyc_value == "1" ? true : false) : field.kyc_value
                temp.key = field.kyc_key
                if (field.kyc_type.toLowerCase() == 'text') { temp.type = 'text' } 
                else if (field.kyc_type.toLowerCase() == 'checkbox'){
                  temp.type = 'checkbox'
                  temp.checked = temp.value
                } else {
                  temp.type = 'select'
                  temp.options = ['option1', 'option2', 'option3']
                }
                customer_org_pt[field.kyc_label] = temp
              })
            }
          }
          this.customer_org_pt = customer_org_pt
        })
        .catch(error => {
          loader.hide()
          Vue.notify({text: error.body, type: 'error'})
        })
    },
    hideModel () {
      this.is_show = false
      for (const key in this.customer_org_pt) {
        this.customer_org_pt[key].value = ''
      }
    },
    refresh_data () {
      this.$refs.customerOrgPtTbl.getAllCusOrgPt()
      this.$refs.CustomerOrgFtr.disableCustomer()
    },
    setTodayDate () {
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.customer_org_pt.billing_date.disabledDate.to = yesterday
      this.customer_org_pt.renewal_date.disabledDate.to = yesterday
      this.customer_org_pt.bill_start_date.disabledDate.to = yesterday
    },
    filterPlatform (platform, customer, custom_fields, extra) {
      this.is_show = true
      this.license_id = ''
      this.customer_org_pt = this.customer_org_pt_
      this.customer_org_pt.renewal_date.value = new Date()
      this.customer_org_pt.bill_start_date.value = new Date()
      this.customer_org_pt.billing_date.value = new Date()
      for (const key in platform) {
        if (this.customer_org_pt[key]) { this.customer_org_pt[key].value = platform[key] }
      }
      for (const key in customer) {
        if (this.customer_org_pt[key]) { this.customer_org_pt[key].value = customer[key] }
      }
      const customer_org_pt = {}
      for (const key in this.customer_org_pt) {
        customer_org_pt[key] = this.customer_org_pt[key]
        if (key == 'mgmnt_port') {
          customer_org_pt[key] = this.customer_org_pt[key]
          custom_fields.forEach(field => {
            const temp = {
              name: '',
              value: '',
              required: true,
              type: '',
              key : '',
              checked : false,
              custom_fields: true,
              extra: extra,
            }
            temp.name = field.label
            temp.value = field.default_values
            temp.key = field.key
            if (field.type.toLowerCase() == 'text') { temp.type = 'text' } 
            else if (field.type.toLowerCase() == 'boolean'){
              temp.type = 'checkbox'
              temp.checked = field.checked
            } else {
              temp.type = 'select'
              temp.options = ['option1', 'option2', 'option3']
            }
            customer_org_pt[field.label] = temp
          })
        }
        if((key=='platform_name') && (this.customer_org_pt[key].value == 'CDN')){
          var pos = {
            name : 'reference name',
            value: '',
            type:'text',
            required:true,
            checked : false,
          }
          customer_org_pt['reference_name'] = pos
        }
      }
      this.customer_org_pt = customer_org_pt
    },
  },
}
</script>

<template>
  <va-card v-if="!newAdd" title="Customer Organization Platform">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="newAdd = !newAdd">Add</va-button>
      <va-button color="orange" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>
    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="edit" slot-scope="props">
        <va-button flat small
          color="gray" icon="fa fa-pencil"
          @click="editCOP(props.rowData)"
          class="ma-0"/>
      </template>
    </va-data-table>
  </va-card>
  <CustomerOrgPt
    v-else
    ref="CustomerOrgPt"
    @new-add-cancel="newAdd = !newAdd" />

</template>

<script>
import CustomerOrgPt from './CustomerOrgPt'
import { debounce } from 'lodash'
import config from '../../i18n/en.json'
import { search } from '../../i18n/search.js'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)
export default {
  name: 'customer_org_pt_lt',
  components: {
    CustomerOrgPt,
  },
  created () {
    window.addEventListener('unload', this.doOnReload())
  },
  data () {
    return {
      term: null,
      perPage: '10',
      newAdd: false,
      cusOrgPt: [],
      message: '',
    }
  },
  watch: {
    newAdd () {
      this.getAllCusOrgPt()
    },
  },
  computed: {
    fields () {
      return [{
        name: 'sno',
        width: '10%',
      }, {
        name: 'customer_org_name',
        title: 'Customer Org Name',
        width: '20%',
      }, {
        name: 'platform_name',
        title: 'Platform Name',
        width: '60%',
      }, {
        name: '__slot:edit',
        dataClass: 'text-right',
        width: '10%',
      }, {
        name: '__slot:delete',
        dataClass: 'text-right',
        width: '10%',
      }]
    },
    filteredData () {
      const filteredCusOrgPt = search(this.term, this.cusOrgPt)
      return filteredCusOrgPt
    },
  },
  methods: {
    exportData () {
      this.cusOrgPt.map(function(item){
        item.platform_name = item.platform_name.split(",").join(":")
      })
      var rowKeys = {
        customer_org_name: 'Customer Organization',
        customer_name: 'Customer Name',
        platform_name: 'Platform Name'
      }
      exportToFile(this.cusOrgPt, rowKeys, 'Customer Org Platform', 'CSV')
      this.getAllCusOrgPt()
    },
    doOnReload () {
      const addedCops = Vue.$cookies.get('added-cops')
      Vue.$cookies.set('added-cops', JSON.stringify([]))
      const addedKycs = Vue.$cookies.get('added-kycs')
      Vue.$cookies.set('added-kycs', JSON.stringify([]))
      Vue.$cookies.remove('selected-customer')
      this.deleteKycs(JSON.parse(addedKycs))
        .then(() => this.deleteCops(JSON.parse(addedCops)))
        .then(() => {
          this.getAllCusOrgPt()
        })
    },
    async deleteCops (addedCops) {
      for (let index = 0; index < addedCops.length; index++) {
        await new Promise((resolve, _) => {
          this.$http.delete(`${config.menu.host}customer_org_pt/${addedCops[index]}`)
            .then((res) => {
              resolve(res)
            })
        })
      }
    },
    async deleteKycs (addedKycs) {
      for (let index = 0; index < addedKycs.length; index++) {
        await new Promise((resolve, _) => {
          this.$http.delete(`${config.menu.host}customer_org_pt_kyc/${addedKycs[index]}`)
            .then((res) => {
              resolve(res)
            })
        })
      }
    },
    editCOP (customer) {
      Vue.$cookies.set('selected-customer', customer.customer_org_id)
      Vue.$cookies.set('edit-mode', true)
      this.newAdd = !this.newAdd
    },
    cancel () {
      this.getAllCusOrgPt()
    },
    getAllCusOrgPt () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.cusOrgPt = []
      this.$http.get(`${config.menu.host}customer_org_pt/`)
        .then(res => {
          loader.hide()
          let customer_org_ids = []
          res.data.forEach(customer => customer_org_ids.push(customer.customer_org_id))
          customer_org_ids = Array.from(new Set(customer_org_ids))
          customer_org_ids.map((id, i) => {
            let platform_name = ''
            res.data.forEach(data => {
              if (id == data.customer_org_id && !platform_name.includes(data.platform_name)) { platform_name += `${data.platform_name},` }
            })
            platform_name = platform_name.slice(0, platform_name.length - 1)
            customer_org_ids[i] = {
              customer_org_id: id,
              platform_name: platform_name,
            }
          })
          customer_org_ids.map((id, i) => {
            return this.getCustomerByID(id.customer_org_id)
              .then((res_) => {
                this.cusOrgPt.push({
                  sno: i + 1,
                  customer_org_name: res_.customer_org_name,
                  customer_org_id: res_.customer_org_id,
                  customer_name: res_.customer_name,
                  platform_name: id.platform_name,
                })
              })
          })
        })
        .catch(error => {
          loader.hide()
          Vue.notify({ text: error.body,type: 'error'})
        })
    },
    async getCustomerByID (customer_org_id) {
      return await new Promise((resolve, _) => this.$http
        .get(`${config.menu.host}customer_org/${customer_org_id}`)
        .then((res) => {
          res = res.data[0]
          resolve(res)
        }))
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>

<style scoped>

</style>

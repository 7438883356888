<template>
  <div>
    <va-modal
      class="flex xs12"
      v-model="isShow"
      title="update customer organization platform"
      size='small'
      okText=''
      cancelText=''
      noOutsideDismiss
      noEscDismiss>
      <va-button small color="danger" 
      icon="entypo entypo-cancel" 
      class="model_cancel" @click.prevent="$emit('hide-model')">
      </va-button>
      <form @submit.prevent="updateCustomer()">
        <div
          v-for="(obj,i) in customerOrgPt_"
          :key="i">
          <label
            v-if="obj.type == 'date'"
            class="date_picker_label">{{getLabel(obj)}}</label>
          <datepicker
            v-if="obj.type == 'date'"
            class="date_picker"
            v-model="obj.value"
            format="MMM dd yyyy"
            :disabledDates="obj.disabledDate"/>
          <va-input 
            v-if="obj.type == 'text' || obj.type == 'number' || obj.type == 'password'"
            v-model="obj.value"
            :label="getLabel(obj)"
            :type="obj.type"
            :placeholder="getPlaceholder(obj)"
            :required="getRequired(obj)"
            :pattern="obj.pattern"
            :disabled="obj.disabled"
            :min="obj.type == 'number' && 0"/>
          <va-select
            v-if="obj.type == 'select'"
            v-model="obj.value"
            :label="getLabel(obj)"
            :options="obj.options"
            :placeholder="getPlaceholder(obj)"
            :required="getRequired(obj)"
            noClear/>
          <va-checkbox
            v-if="obj.type == 'checkbox'"
            :label="getLabel(obj)"
            v-model="obj.checked"/> <br v-if="obj.type == 'checkbox'">
        </div>
        <div class="d-flex justify--center mt-3">
          <va-button class="my-0" flat
            @click.prevent="$emit('hide-model')">Cancel</va-button>
          <va-button type="submit" class="my-0">Update</va-button>
        </div>
      </form>
    </va-modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import config from '../../i18n/en.json'
import Vue from 'vue'
import vueResource from 'vue-resource'
import REGEX from '../../i18n/regex_pattern'

Vue.use(vueResource)

export default {
  name: 'customer_org_pt_update',
  components: { Datepicker },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    customerOrgPt: {
      type: Object,
    },
    license_id: '',
    kyc_id: '',
  },
  data () {
    return {
      typeOtns: ['1', '2', '3'],
      customerOrgPt_: [],
    }
  },
  watch: {
    isShow () {
      this.customerOrgPt_ = []
      Object.entries(this.customerOrgPt).forEach(key => {
        var keyValue = key[1].key
        key = key[0]
        this.customerOrgPt[key].key = key
        this.customerOrgPt[key].dbkey = keyValue
        this.customerOrgPt_.push(this.customerOrgPt[key])
      })
    },
  },
  methods: {
    successRes (payload) {
      Vue.$cookies.set('selected-customer', payload.customer_org_id)
      this.$emit('data-updated')
      this.$emit('hide-model')
      Vue.notify({text: 'Customer Organization Platform Created Successfully',type: 'success',
      })
    },
    errorRes (err) {
      Vue.notify({ text: err.body,type: 'error'})
    },
    addField (fields) {
      fields.push({
        label: '',
        key: '',
        default_values: '',
        type: '',
      })
    },
    removeField (fields, index) {
      this.customerOrgPt_.forEach(customer => {
        if (customer.key == 'custom_fields') {
          customer.value = fields.filter((_, i) => i != index)
        }
      })
    },
    validateCustomFields (fields) {
      const validation = fields.every(field =>
        Object.entries(field).every(fieldKey => field[fieldKey[0]]),
      )
      return validation
    },
    updateCustomer () {
      const payload = {}
      let validation = true
      this.customerOrgPt_.forEach((org) => {
        if (org.type && org.type == "checkbox") { org.value = org.checked }  
        if (org.type != "checkbox" && (!org.value || org.value == 'Invalid date') ) { validation = false }
        payload[org.key] = org.value
      })
      if (!validation) {
        Vue.notify({text: 'Please fill the form correctly', type: 'error' })
        return
      }
      const arr = []
      this.customerOrgPt_.forEach(cop => {
        if (cop.custom_fields) {
          delete payload[cop.name]
          arr.push({
              license_id  : this.license_id,
              customer_org_id : payload.customer_org_id,
              kyc_id  : cop.extra.kyc_id,
              platform_id : cop.extra.platform_id,
              kyc_key : cop.dbkey,
              kyc_value : cop.value,
              kyc_label : cop.name,
              kyc_type  : cop.type
            })
        }
      })
      payload.customer_org_pt_kycs = arr
      if(payload.reference_name){
        if(!REGEX.OnlyLower(payload.reference_name)) {
          return Vue.notify({text: 'Only Lower Case is allowed for reference_name', type: 'error' })
        }
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      if (!this.license_id) {
        this.$http.post(`${config.menu.host}customer_org_pt`, payload)
          .then((res) => {
            loader.hide()
            Vue.$cookies.set('added-cops', JSON.stringify(
              [...JSON.parse(Vue.$cookies.get('added-cops')), res.data.license_id],
            ))
          this.successRes(payload)
          }).catch(err => {
            loader.hide()
            this.errorRes(err)
          })
      } else {
        this.$http.put(`${config.menu.host}customer_org_pt/${this.license_id}`, payload)
          .then(() => {
            loader.hide()
            this.successRes(payload)
          }).catch(err => {
            loader.hide()
            this.errorRes(err)
          })
      }
    },
    getLabel (obj) {
      return obj.required ? `${obj.name} *`.toUpperCase() : `${obj.name} `.toUpperCase()
    },
    getPlaceholder (obj) {
      return `Enter ${obj.name}`
    },
    getRequired (obj) {
      return !!obj.required
    },
  },
}
</script>

<style scoped>
.date_picker_label {
  color: #2c82e0;
  font-size: 0.7rem;
  margin-left: 10px;
  font-weight: bold;
}

.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
}

.toCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content_width {
  width: 30px;
  height: 30px;
}

.model_cancel {
  float: right;
  margin-top: -8%;
  margin-right: -3%;
}
</style>

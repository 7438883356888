<template>
  <div class="filter">
    <va-select
      label="Customer Org Name *"
      v-model="stCustomer"
      :options="customerOtns"
      placeholder="Select Customer Org Name"
      textBy="customer_org_name"
      :disabled="disabled"
      noClear/>
    <va-select
      label="Platform Name *"
      searchable
      v-model="stPlatform"
      :options="platformOtns"
      placeholder="Select Platform Name"
      textBy="platform_name"
      noClear/>
    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0"
        @click.prevent="filter()"
        :disabled="!stPlatform || !stCustomer">submit</va-button>
    </div>
  </div>
</template>

<script>
import config from '../../i18n/en.json'
import Vue from 'vue'
import vueResource from 'vue-resource'

Vue.use(vueResource)
export default {
  name: 'customer_org_filter',
  created () {
    this.getAllCustomer()
  },
  data () {
    return {
      platformOtns: [],
      customerOtns: [],
      stPlatform: '',
      stCustomer: '',
      custom_fields: [],
      disabled: false,
    }
  },
  methods: {
    filter () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http
        .get(config.menu.host + `platform_kyc/${this.stPlatform.platform_id}`)
        .then(res => {
          loader.hide()
          const custom_fields = res.data.length ? res.data[0].custom_fields : '[]'
          this.custom_fields = JSON.parse(custom_fields)
          const extra = res.data.length ? {
            kyc_id: res.data[0].kyc_id,
            platform_id: res.data[0].platform_id,
          } : {}
          this.$emit('filter-pt', this.stPlatform, this.stCustomer, this.custom_fields, extra)
          this.stPlatform = ''
        })
        .catch(() => loader.hide())
    },
    getAllPlatform (customer_org_id) {
      this.platformOtns = []; var arr = []
      this.$http.get(config.menu.host + 'platform').then(res => {
        this.$http.get(config.menu.host + 'user/' + this.$cookies.get('user-id')).then(response => {
          if(response.body.role == "SYS_ADMIN"){
              res.body.forEach((platform, i) => {
                platform.id = i
                this.platformOtns.push(platform)
              })
              if (customer_org_id) this.getPlatformByID(customer_org_id)
          }else{
            this.$http.get(config.menu.host + 'org/' + response.body.org_id).then(resp => {
              arr = res.data.filter(function (item) {
                return resp.data.org_platforms.some(function (prop) {
                  if(item.platform_name == prop.platform_name){
                    return item.installation_type = prop.installation_type
                  }
                })
              })
              arr.forEach((platform, i) => {
                platform.id = i
                this.platformOtns.push(platform)
              })
              if (customer_org_id) this.getPlatformByID(customer_org_id)
            })
          }
        })
      })
    },
    getPlatformByID (customer_org_id) {
      this.$http.get(`${config.menu.host}customer_org_pt/${customer_org_id}`)
      .then(res => {
        res.data = res.data.map(data => data.platform_id)
        this.platformOtns = this.platformOtns.filter(function (platform){
          return ((platform.installation_type == 'MULTIPLE')) || (!res.data.includes(platform.platform_id))
        })
        this.stPlatform = ''
      })
    },
    getAllCustomer () {
      this.$http.get(config.menu.host + 'customer_org').then(res => {
        this.getAllCusOrgPt().then(customer_org_pt => {
          const customer_org_id = []
          const customer_org_status = {}
          customer_org_pt.forEach(customerOrgPt => {
            customer_org_id.push(customerOrgPt.customer_org_id)
            customer_org_status[customerOrgPt.customer_org_id] = customerOrgPt.license_status
          })
          res.data = res.data
            .filter(customer => !customer_org_id.includes(customer.customer_org_id))
          res.data.forEach((customer, i) => {
            customer.id = i
            this.customerOtns.push(customer)
          })
          this.disableCustomer()
        })
      })
    },
    async getAllCusOrgPt () {
      return new Promise((resolve, reject) =>
        this.$http.get(`${config.menu.host}customer_org_pt`).then(res => {
            resolve(res.data)
        }).catch(err => reject(err)),
      )
    },
    getCustomerByID (customer_org_id) {
      this.$http.get(`${config.menu.host}customer_org/${customer_org_id}`)
        .then((res) => {
          if (!res.data[0]) return
          res = res.data[0]
          res.id = 1
          this.stCustomer = res
        })
    },
    disableCustomer () {
      const customer_org_id = Vue.$cookies.get('selected-customer')
      if (!customer_org_id) {
        this.getAllPlatform(customer_org_id)
        this.disabled = false
      } else {
        this.getCustomerByID(customer_org_id)
        this.getAllPlatform(customer_org_id)
        this.disabled = true
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-modal
      v-model="showRemoveModal"
      title="Remove Platform"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deletePlatform(removed_row_content)"
      @cancel="cancel()" />
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="edit" slot-scope="props">
        <va-button flat small
          v-if="props.rowData.license_status != 'ACTIVE'"
          color="gray" icon="fa fa-pencil"
          @click="editPlatform(props.rowData)"
          class="ma-0"/>
      </template>
      <template slot="delete" slot-scope="props">
        <va-button flat small
          v-if="props.rowData.license_status != 'ACTIVE'"
          color="danger" icon="fa fa-remove"
          @click="removePlat(props.rowData)"
          class="ma-0"/>
      </template>
    </va-data-table>
    <div class="d-flex justify--center mt-3">
      <va-button type="submit"
        v-if="!license_id"
        class="my-0"
        @click.prevent="create()"
        :disabled="disabled">Submit</va-button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import config from '../../i18n/en.json'
import { search } from '../../i18n/search.js'
import Vue from 'vue'
import vueResource from 'vue-resource'

Vue.use(vueResource)
export default {
  name: '',
  props: {
    license_id: '',
  },
  created () {
    this.getAllCusOrgPt()
  },
  data () {
    return {
      term: null,
      perPage: '5',
      customer_org_id: null,
      cusOrgPt: [],
      disabled: true,
      removed_row_content: {},
      message: '',
      showRemoveModal: false,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'sno',
        width: '10%',
      }, {
        name: 'customer_org_name',
        title: 'Customer Org Name',
        width: '20%',
      }, {
        name: 'platform_name',
        title: 'Platform Name',
        width: '20%',
      }, {
        name: 'license_status',
        title: 'Status',
        width: '80%',
      }, {
        name: '__slot:edit',
        dataClass: 'text-right',
        width: '10%',
      }, {
        name: '__slot:delete',
        dataClass: 'text-right',
        width: '10%',
      }]
    },
    filteredData () {
      const filteredCusOrgPt = search(this.term, this.cusOrgPt)
      return filteredCusOrgPt
    },
  },
  methods: {
    getCustomerOrgID () {
      const customer_org_id = Vue.$cookies.get('selected-customer')
      if (customer_org_id) this.customer_org_id = customer_org_id
    },
    create () {
      this.cusOrgPt.map(cop => cop.license_status = cop.license_status == 'DRAFT' ? 'NEW' : cop.license_status)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(`${config.menu.host}customer_org_pt`, this.cusOrgPt)
      .then(res => {
        loader.hide()
        Vue.notify({text: res.body, type: 'success'})
        Vue.$cookies.remove('selected-customer')
        Vue.$cookies.set('added-cops', JSON.stringify([]))
        Vue.$cookies.set('added-kycs', JSON.stringify([]))
        this.customer_org_id = null
        this.$emit('remove-disable')
        this.$emit('cancel-add')
      })
      .catch(error => {
        loader.hide()
        Vue.notify({ text: error.body,type: 'error'})
      })
    },
    editPlatform (cusOrgPt) {
      this.$emit('show-model', cusOrgPt)
    },
    cancel () {
      this.getAllCusOrgPt()
    },
    removePlat (data) {
      this.message = 'Are you sure to delete ' + data.platform_name + ' ?'
      this.showRemoveModal = true
      this.removed_row_content = data
    },
    deletePlatform (cusOrgPt) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(`${config.menu.host}customer_org_pt/${cusOrgPt.license_id}`)
      .then(() => {
        loader.hide()
        this.cusOrgPt = this.cusOrgPt.filter(org => org.sno != cusOrgPt.sno)
        if (!this.cusOrgPt.length) {
          Vue.$cookies.remove('selected-customer')
          this.customer_org_id = null
        }
        this.$emit('remove-disable')
        this.cusOrgPt.map((org, i) => org.sno = i + 1)
      })
      .catch(error => {
        loader.hide()
        Vue.notify({text: error.body,type: 'error'})
      })
    },
    getAllCusOrgPt () {
      this.getCustomerOrgID()
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(`${config.menu.host}customer_org_pt/${this.customer_org_id}`)
      .then(res => {
        loader.hide()
        this.cusOrgPt = []
        res.data.map((org, i) => org.sno = i + 1)
        this.cusOrgPt = res.data
        if (!this.cusOrgPt.length) this.disabled = true
        else this.disabled = false
      })
      .catch(error => {
        loader.hide()
        Vue.notify({text: error.body,type: 'error'})
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
